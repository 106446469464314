import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import Link from "@mui/material/Link";
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import { Grid } from '@mui/material';
import NavBar from "../NavBar/NavBar";
import LoginBox from "../LoginBox/LoginBox";
import useClasses from "../../hooks/styles";

import "./Logo.css";
import logo from "../../assets/logo.png";

function Logo(props) {
  const theme = useTheme();

  const styles = {
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  };
  const classes = useClasses(styles);

  return (
    <div>
      
      <div class="logoWrapper">
        <img src={ logo } className="logoIcon" />
      </div>
      <div class="logoTextWrapper">
        <Typography color="primary" className={"logoText"}>
          Property health notification system for peace of mind.
        </Typography>
      </div>
    </div>
  );
}

export default Logo;
