// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    font-size: 0;
    margin: 0;
}

.div {
    font-size: 0;
    margin: 0;
}

.logoWrapper {
    top: 0;
    left: -1px;
    width: 190px;
    height: 90px;
    display: inline-block;
}

.logoTextWrapper {
    position: relative;
    padding-left: 95px;
    font-size: var(--font-size-6xl);
    font-family: var(--font-lato);
}

.logoText {
    margin: 0;
}

.logoIcon {
    height: 100px;
    width: 200px;
    top: 0;
    right: 0;
    bottom: 12px;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.centered {
    display: flex;
    height: 60%;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,MAAM;IACN,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,+BAA+B;IAC/B,6BAA6B;AACjC;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,MAAM;IACN,QAAQ;IACR,YAAY;IACZ,OAAO;IACP,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".container {\n    font-size: 0;\n    margin: 0;\n}\n\n.div {\n    font-size: 0;\n    margin: 0;\n}\n\n.logoWrapper {\n    top: 0;\n    left: -1px;\n    width: 190px;\n    height: 90px;\n    display: inline-block;\n}\n\n.logoTextWrapper {\n    position: relative;\n    padding-left: 95px;\n    font-size: var(--font-size-6xl);\n    font-family: var(--font-lato);\n}\n\n.logoText {\n    margin: 0;\n}\n\n.logoIcon {\n    height: 100px;\n    width: 200px;\n    top: 0;\n    right: 0;\n    bottom: 12px;\n    left: 0;\n    max-width: 100%;\n    overflow: hidden;\n    max-height: 100%;\n    object-fit: cover;\n}\n\n.centered {\n    display: flex;\n    height: 60%;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
