import React, { useEffect, useState, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import EqualizerIcon from "@mui/icons-material/Equalizer";

import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import "./Portal.css";
import NavBar from "../NavBar/NavBar";
import GraphBox from "../GraphBox/GraphBox";
import TableBox from "../TableBox/TableBox";
import useClasses from "../../hooks/styles";
import { SensorContext } from "../../contexts/SensorContext";
import { LoginContext } from "../../contexts/LoginContext";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Colca
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const Portal = (props) => {
  // Set Context to have a global variable between components
  
  
  const [content, setContent] = useState({});
  const [data, setData] = useState([]);
  const [slist, setList] = useState([]);
  const [serial, setSerial] = useState("");
  const [sensor, setSensor] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [range, setRange] = useState(1);
  const [ready, setReady] = useState(false);
  const [value, setValue] = React.useState('1');
  
  // States used for tabs
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();

  const styles = theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    container: {
      margin: theme.spacing(3, 0, 3),
    },
    tab: {
      backgroundColor: theme.palette.secondary.main,
      display: "flex",
      flexDirection: "column",
      alignItems: "self-start",
      verticalAlign: "top",

    },
  });
  const classes = useClasses(styles);

  const history = useNavigate();
  useEffect(() => {
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
    fetch(`${process.env.REACT_APP_CMS_URL}/api/users/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    }).then((response) => {
      if (!response.ok) {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        setLoggedIn(false);
        history("/");
      } else {
        console.log("no token");
        fetch(`${process.env.REACT_APP_CMS_URL}/api/pages?filters[type][$eq]=portal&populate=*`)
          .then((res) => res.json())
          .then((json) => {
            setContent(json.data[0].attributes);
          })
          .then((after) => {
            fetch(`${process.env.REACT_APP_CMS_URL}/api/sensors`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access"),
              },
            })
              .then((r) => r.json())
              .then((j) => {
                console.log("setting data");
                console.log(j);
                setData(j.data);
                setLoggedIn(true);
              });
          });
      }
    });
  }, []);

  useEffect(() => {
    console.log("serial change");
    console.log(serial);
    if (serial != "") {
      setReady(true);
    }
  }, [serial]);

  useEffect(() => {
    console.log("data altered");
    if (data.length) {
      console.log("found data");
      console.log(data);
      setList([...new Set(data.map((item) => { return { serial_number: item.attributes.serial_number, location: item.attributes.location } }))]);
    }
  }, [data]);

  useEffect(() => {
    console.log(slist);
    console.log(slist.length);  
    if (slist.length) {
      setSerial(slist[0].serial_number);
      setSensor(slist[0]);
      console.log(slist[0]);
      console.log("serial set");
    }
  }, [slist]);

  useEffect(() => {
    console.log("Range change");
  }, [range]);

  function handleChange(event, newValue) {
    setValue(newValue);
  };

  function handleSerial(snum) {
    console.log("in function");
    setSerial(snum);
  }

  function handleMenuClick (index) {
    history(`/sensor/${ tabValue }`);
  };

  function handleMenuOpen(index, event) {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setOpen(true);
    setTabValue(index);
    console.log("VALUE:");
    console.log(index);
  };

  function handleMenuClose() {
    console.log("on handlemenuclose");
    setAnchorEl(null);
    setOpen(false);
  };

  function SensorLabel(sensor_object) {

    console.log("1111111111111111111111111111111111111");
    console.log(sensor_object);
    console.log(sensor_object.location);

    return (
      <Typography>
        { sensor_object.sensor_object.location }
      </Typography>
    );
  }

  if (ready) {
    console.log('==================');
    console.log(content);
    console.log(sensor);
    //const bg_url =
    //  `${process.env.REACT_APP_CMS_URL}` + content.background_image.data.attributes.url;
    const background = {
      overflow: "auto",
      //backgroundImage: "url(" + bg_url + ")",
    };

    const subItems = ["Edit"];

    const tabSx = {
      '& .MuiTabs-indicator': {
        backgroundColor: 'red',
      },
      '& .MuiButtonBase-root.MuiTab-root': {
        color: 'black',
        transition: 'color 0.2s ease-in-out',
        '&:hover': {
          color: 'green)',
        },
        '&.Mui-selected': {
          color: 'red',
        },
      },
    };

    return (
      <SensorContext.Provider value={{ sensor, setSensor }}>
        <div class="background" style={background}>
          <LoginContext.Provider value={{ loggedIn, setLoggedIn }}>
            <NavBar content={content} />
          </LoginContext.Provider>

          <TabContext value={value}>
            <Box sx={{ height: "100%", flexGrow: 1, display: 'flex' }}>
              <div class="tabBar" onMouseLeave={handleMenuClose}>
                <TabList orientation="vertical" onChange={handleChange} sx={{alignItems: 'start', zIndex: "100 !important"}}>
                  <Tab className={classes.tab} sx={{alignItems: 'start', zIndex: "100 !important"}} label="Overview" value="1" />
                </TabList>
                {
                  slist.map((sensor_tab, index) => (
                    <TabList sx={{alignItems: 'start', zIndex: "100 !important"}} onChange={handleChange} >
                      <div>
                        <Tab label={ <SensorLabel sensor_object={sensor_tab} /> } value={sensor_tab.serial_number} 
                          onMouseEnter={handleMenuOpen.bind(this, sensor_tab.serial_number)}
                        />
                      </div>
                      { open && 
                        <TabList orientation="vertical" sx={{alignItems: 'start', zIndex: "100 !important"}}>
                          {subItems.map((item, index) => (
                            <Tab value={index} label={<div className="subTabLabel">{item}</div>} onClick={handleMenuClick.bind(this, index)} />
                          ))}
                        </TabList>
                      }
                      
                    </TabList>
                  ))
                }
                
              </div>

              <TabPanel value="1" sx={{
                  position: "absolute",
                  left: '0',
                  right: '0',
                }}>
                  <Container
                    maxWidth="lg"
                  >
                    <TableBox serials={[data]}/>
                  </Container>

                  <Container
                    maxWidth="lg"
                  >
                    <Container component="main" maxWidth="xs">
                      <CssBaseline />
                      <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                          <EqualizerIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                          Dashboard
                        </Typography>
                      </div>
                    </Container>

                    <GraphBox key={[serial, range]} serial={serial} range={range} />
                  </Container>
                </TabPanel>
                <TabPanel value="2">
                  Item Two
                </TabPanel>
                <TabPanel value="3">
                  Item Three
                </TabPanel>
            </Box>
          </TabContext>
          
        </div>
      </SensorContext.Provider>
    );
  } else {
    return "LOADING...";
  }
}

class Portal_new extends React.Component {
  constructor() {
    super();
    this.state = {
      page_content: {},
      loading: true,
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_CMS_URL}/api/pages?type=portal`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          page_content: json[0],
          loading: false,
        });
        return json;
      });
  }

  render() {
    if (this.state.loading) {
      return "Loading...";
    }

    const classes = this.props.classes;
    const bg_url =
      `${process.env.REACT_APP_CMS_URL}/api/` +
      this.state.page_content.background_image.url;

    const background = {
      overflow: "auto",
      backgroundImage: "url(" + bg_url + ")",
    };

    return (
      <div style={background}>
        <NavBar content={this.state.page_content} />
        <div>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <EqualizerIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Dashboard
              </Typography>
            </div>
          </Container>
          <GraphBox />
        </div>
      </div>
    );
  }
}
