// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whole_page {
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
    background-size: cover;
}

.page_div {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.logo {
    position: relative;
}

.navBar {
    display: inline-block;
    top: 0;
    right: 0;
}

.centered {
    display: flex;
    height: 60%;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,UAAU;IACV,WAAW;IACX,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,MAAM;IACN,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".whole_page {\n    position:fixed;\n    width:100%;\n    height:100%;\n    top:0px;\n    left:0px;\n    z-index:1000;\n    background-size: cover;\n}\n\n.page_div {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    background-size: cover;\n}\n\n.logo {\n    position: relative;\n}\n\n.navBar {\n    display: inline-block;\n    top: 0;\n    right: 0;\n}\n\n.centered {\n    display: flex;\n    height: 60%;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
