// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_div {
    position: relative;
    top: 100%;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.logo {
    position: relative;
}

.navBar {
    position: absolute;
    top: 0;
    right: 0;
}

.centered {
    display: flex;
    height: 60%;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/SensorPage/SensorPage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".page_div {\n    position: relative;\n    top: 100%;\n    width: 100%;\n    height: 100%;\n    background-size: cover;\n}\n\n.logo {\n    position: relative;\n}\n\n.navBar {\n    position: absolute;\n    top: 0;\n    right: 0;\n}\n\n.centered {\n    display: flex;\n    height: 60%;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
