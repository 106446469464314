// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.loading_div {
    margin: auto;
    text-align: center;
}

.graphbox-main {
    margin-left: auto;
    margin-right: auto;
}

.dygraph-legend {
    position: absolute;
    font-size: 14px;
    z-index: 10;
    padding: 0.7rem 1rem;
    background: white;
    line-height: normal;
    text-align: left;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px,
      rgba(67, 90, 111, 0.47) 0px 2px 4px -2px;
}

.dygraph-legend-line {
    display: inline-block;
    position: relative;
    padding-left: 13px;
    margin-right: 8px;
    border-bottom-width: 13px;
    border-bottom-style: solid;
}

.dygraph-legend-row {
    display: flex;
    align-items: center;
    margin-top: 6px;
    flex-wrap: nowrap;
}

.dygraph-axis-label {
    /* position: absolute; */
    font-size: 12px;
    z-index: 10;
    line-height: normal;
    overflow: hidden;
    color: black;
    opacity: 0.8;
}

.dygraph-axis-label-y {
    padding-right: 0.3rem;
}

.dygraph-ylabel {
    transform: rotate(270deg);
    vertical-align: middle;
    margin-left: auto; 
    margin-right: 0;
}

.notificationMessage {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/GraphBox/GraphBox.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,oBAAoB;IACpB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB;8CAC0C;AAC9C;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;IACzB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;IACxB,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["\n.loading_div {\n    margin: auto;\n    text-align: center;\n}\n\n.graphbox-main {\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.dygraph-legend {\n    position: absolute;\n    font-size: 14px;\n    z-index: 10;\n    padding: 0.7rem 1rem;\n    background: white;\n    line-height: normal;\n    text-align: left;\n    overflow: hidden;\n    border-radius: 4px;\n    box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px,\n      rgba(67, 90, 111, 0.47) 0px 2px 4px -2px;\n}\n\n.dygraph-legend-line {\n    display: inline-block;\n    position: relative;\n    padding-left: 13px;\n    margin-right: 8px;\n    border-bottom-width: 13px;\n    border-bottom-style: solid;\n}\n\n.dygraph-legend-row {\n    display: flex;\n    align-items: center;\n    margin-top: 6px;\n    flex-wrap: nowrap;\n}\n\n.dygraph-axis-label {\n    /* position: absolute; */\n    font-size: 12px;\n    z-index: 10;\n    line-height: normal;\n    overflow: hidden;\n    color: black;\n    opacity: 0.8;\n}\n\n.dygraph-axis-label-y {\n    padding-right: 0.3rem;\n}\n\n.dygraph-ylabel {\n    transform: rotate(270deg);\n    vertical-align: middle;\n    margin-left: auto; \n    margin-right: 0;\n}\n\n.notificationMessage {\n    max-width: fit-content;\n    margin-left: auto;\n    margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
