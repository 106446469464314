import { useCallback } from "react";
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styles from "./Register.module.css";
import Input from 'react-phone-number-input/input'

const Register = () => {
  const [serial, setSerial] = useState('');
  const [number, setNumber] = useState(0);

  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/verify");
  }, [navigate]);

  const onErrorTextClick = useCallback(() => {
    navigate("/registererror-serial");
  }, [navigate]);

  const handleSubmit = (event, data) => {
    alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
  }

  return (
    <div className={styles.register}>
      <img
        className={styles.yellowBoatSailingBetweenLaIcon}
        alt=""
        src="/undefined.png"
      />
      <div className={styles.registerChild} />
      <div className={styles.registerItem} />
      <form onSubmit={ handleSubmit }>
        <div className={styles.serialNumber}><input type="text" value="Serial number" /></div>
        <div className={styles.cellPhoneNumber}><Input country="US" placeholder="Cell phone number" value={ number } onChange={ setNumber } /></div>
      </form>
      <div className={styles.contactUs}>Contact Us</div>
      <div className={styles.help}>Help</div>
      <div className={styles.dolorSitAmetContainer}>
        <p
          className={styles.dolorSitAmet}
        >{`dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor `}</p>
        <p
          className={styles.dolorSitAmet}
        >{`incididunt ut labore et dolore magna aliqua. In massa tempor nec `}</p>
        <p className={styles.dolorSitAmet}>
          feugiat nisl pretium fusce. Malesuada fames ac turpis
        </p>
      </div>
      <div className={styles.path409Parent} onClick={onGroupContainerClick}>
        <img className={styles.path409Icon} alt="" src="/undefined10.png" />
        <div className={styles.verify}>“Verify”</div>
      </div>
      <div className={styles.colcaLogo111VerySmall}>
        <img
          className={styles.colcaLogo111VerySmallIcon}
          alt=""
          src="/undefined1.png"
        />
      </div>
      <i className={styles.error} onClick={onErrorTextClick}>
        “error”
      </i>
    </div>
  );
};

export default Register;
