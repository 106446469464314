import React, { useState, useEffect, useContext } from 'react';
import { SensorContext } from '../../contexts/SensorContext';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridCellEditStopReasons } from '@mui/x-data-grid';
import Button from '@mui/material/Button';


function TableBox(props) {
    // Get global context
    const { sensor, setSensor } = useContext(SensorContext);
    console.log(sensor);
    console.log("CONTEXT CHECK");

    // updateModel state used to keep track of changes with the table
    const [originalModel, setOriginalModel] = React.useState([]);
    const [updateModel, setUpdateModel] = React.useState([]);
    const history = useNavigate();
    useEffect(() => {
        console.log("TABLEBOX")
        console.log(props.serials);
        console.log("=====================");
    }, []);

    function handleTableChanges(event) {
        console.log("IN TABLE CHANGES");
        originalModel.forEach(og => {
            console.log(`OG: ${ og.serial_number } | ${ og.location }`)
        });
        updateModel.forEach(og => {
            console.log(`UP: ${ og.serial_number } | ${ og.location }`)
        });

        updateModel.forEach(update => {
            var original_index = originalModel.findIndex(og => og.serial_number == update.serial_number);
            var update_check = props.serials[0].find(serial => { return serial.attributes.serial_number === update.serial_number });
            if (update_check != null) {
                if (originalModel[original_index].location != update.location) {
                    handleEditLocation(update_check.id, update.location);
                    originalModel[original_index] = update;
                    console.log("CHANGED");
                    originalModel.forEach(og => {
                        console.log(`OG: ${ og.serial_number } | ${ og.location }`)
                    });
                }
            }
        });
    }

    function handleEditLocation(id, new_location) {
        fetch(`${process.env.REACT_APP_CMS_URL}/api/users/me`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("access"),
            },
        })
            .then((response) => {
                if (!response.ok) {
                    localStorage.removeItem("access");
                    localStorage.removeItem("refresh");
                    history("/");
                } else {
                    fetch(`${process.env.REACT_APP_CMS_URL}/api/sensors/${id}`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem("access"),
                        },
                        body: JSON.stringify({
                            data: {
                                location: new_location
                            }
                        })
                    })
                        .then((r) => r.json())
                        .then((j) => {
                            console.log(j);
                        });
                }
            });
    }

    const columns = [
        { field: 'serial_number', headerName: 'Serial Number', width: 200 },
        { field: 'location', headerName: 'Location', width: 300, editable: true },
        { field: 'condition', headerName: 'Condition', width: 100 },
        { field: 'lastreading', headerName: 'Last Reading', type: 'date', width: 250 },
    ];

    let rows = [];
    for (var i = 0; i < props.serials[0].length; i++) {
        
        let serial = props.serials[0][i].attributes;
        let row = { id: i, serial_number: serial.serial_number, location: serial.location, condition: serial.status, lastreading: new Date(serial.last_data) };

        rows.push(row);
    }

    return (
        <div>
            <DataGrid 
                rows={rows} 
                columns={columns} 
                // Update selected sensor changes for graph
                onRowSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = rows.filter((row) =>
                        selectedIDs.has(row.id)
                    );
                    console.log(selectedRowData);
                    console.log("SELECTED");
                    if (sensor.serial_number != selectedRowData[0].serial_number) {
                        setSensor(selectedRowData[0]);
                    }
                }}
                processRowUpdate={(updatedRow, originalRow) => {
                    console.log("CELL EDIT");
                    console.log(updatedRow);
                    console.log(originalRow);

                    // Check if original row exists, if not, add it
                    var original_check = originalModel.find(og => og.serial_number == originalRow.serial_number);
                    if (!original_check) {
                        originalModel.push(originalRow);
                    }

                    // Update the new information in updateModel
                    var update_check = updateModel.findIndex(up => up.serial_number == updatedRow.serial_number);
                    if (update_check < 0) {
                        updateModel.push(updatedRow);
                    } else {
                        console.log("EXISTING");
                        console.log(update_check);
                        console.log(updateModel[update_check]);
                        updateModel[update_check] = updatedRow;
                    }
                }}
                SelectionModel={[0]} // Pre-select row
                hideFooterPagination
                hideFooterSelectedRowCount
            />
            <Button
                variant="contained"
                color="secondary"
                onClick={event => {handleTableChanges(event)}}
            >
                Save
            </Button>
        </div>
    )
}

export default TableBox;