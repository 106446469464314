import React, { useState, useEffect, useContext, useRef } from 'react';

function get_sensor(auth, serial_number) {
    return fetch(`${ process.env.REACT_APP_CMS_URL }/api/sensors?serial_number=${ serial_number }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth,
        },
    })
};

export default get_sensor