import React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple, red } from '@mui/material/colors';

import { createRoot } from 'react-dom/client';
import './global.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { PageRoutes } from './routes'; // where we are going to specify our routes

const theme = createTheme({
  palette: {
      primary: {
          main: "#f05a29",
          red: "b2102f"
      },
      secondary: {
          main: "#3f51b5"
      }
  }
});

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
  <ThemeProvider theme={theme}>
    <Router>
      <PageRoutes />
    </Router>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
