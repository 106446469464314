import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import useClasses from '../../hooks/styles';

import './LoginBox.css';
import { LoginContext } from "../../contexts/LoginContext";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Colca
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function LoginBox(props) {
    const theme = useTheme();

    const styles = {
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    };
    const classes = useClasses(styles);

    const [identifier, setID] = useState('');
    const [password, setPass] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [update, setUpdate] = useState('');
    const [error, setError] = useState(false);
    const { loggedIn, setLoggedIn } = useContext(LoginContext);
    console.log(loggedIn);
    useEffect(() => {
        console.log('updated');
        if (localStorage.getItem('access')) {
            console.log(localStorage.getItem('access'));
        } else {
            console.log('NOPE');
        }
    }, [update]);
    
    const auth_url = `${ process.env.REACT_APP_CMS_URL }/api/auth/local`;
    const phone_auth_url = `${ process.env.REACT_APP_CMS_URL }/api/email/phonenumber_login`;
    console.log(auth_url)
    const handle_login = (e, data) => {
	    e.preventDefault();
        console.log(data);

        // Check for phone number
        let response;
        if (/^\d{10}$/.test(data.identifier)) {
            response = fetch(phone_auth_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
        } else {
            response = fetch(auth_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
        }

        
        response.then(res => {
                if (res?.ok) {
                    return res.json();
                } else {
                    throw res?.status;
                }
            })
                .then(json => {
                    console.log(json);
                    localStorage.setItem('access', json.jwt);
                    localStorage.setItem('refresh', json.refresh);
                    setUpdate('login');
                    setLoggedIn(true);
                })
                .catch(error => {
                    console.log(`HTTP Response Code: ${error}`);
                    setError(true);
                });
    }
    const handle_logout = (e) => {
        e.preventDefault();
        localStorage.removeItem('access');
        setUpdate('logout');
        setLoggedIn(false);
    }
    const handleClickShowPassword = () => { setShowPassword(!showPassword); }
    const handleMouseDownPassword = (event) => { event.preventDefault(); };

    if (localStorage.getItem('access')) {
        return
    }

    console.log(error);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    { error &&
                        <Alert variant="filled" severity="error">
                            The user ID or password you entered does not match our records.
                            Please try again. You may also securely reset your password.
                        </Alert>
                    }
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon color="primary" />
                    </Avatar>
                    <Typography component="h1" variant="h4">
                        Sign in
                    </Typography>
                    
                    <form 
                        className={classes.form} 
                        onSubmit={event => {
                            handle_login(event, {identifier, password});
                            setID('');
                            setPass('');
                        }}
                    >
                        <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            id="identifier"
                            label="Email Address or Phone Number"
                            name="identifier"
                            value={identifier}
                            autoComplete="identifier"
                            autoFocus

                            onChange={event => {
                                const { value } = event.target;
                                setID(value);
                            }}
                        />
                        <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"

                            onChange={event => {
                                const { value } = event.target;
                                setPass(value);
                            }}

                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </ThemeProvider>
    );

}
  
export default LoginBox;

LoginBox.propTypes = {
    content: PropTypes.object.isRequired,
};
