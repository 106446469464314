import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import NavBar from "../NavBar/NavBar";
import LoginBox from "../LoginBox/LoginBox";
import useClasses from "../../hooks/styles";

import "./Home.css";
import Logo from "../Logo/Logo.js";
import { LoginContext } from "../../contexts/LoginContext";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Colca
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Home(props) {
  const theme = useTheme();

  const styles = {
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  };
  const classes = useClasses(styles);

  const [{page_content, loading}, setStates] = useState({ page_content: null, loading: true });
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    console.log("BEFORE FETCH");
    fetch(`https://maji.colca.us/cms/api/pages?filters[type][$eq]=home&populate=*`)
      .then((res) => res.json())
      .then((json) => {
        console.log(json.data[0]);
        setStates({ page_content: json.data[0].attributes, loading:false });
        console.log("DID FETCH");
      });
  }, []);

  useEffect(() => {
    console.log("Check if logged in");
    if (localStorage.getItem('access')) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  if (loading) {
    return "LOADING...";
  }

  console.log("HERE");
  console.log(process.env);
  console.log(page_content);
  const bg_url = `${ process.env.REACT_APP_CMS_URL }` + page_content.background_image.data.attributes.url;
  console.log(bg_url);
  const background = {
    backgroundImage: "url(" + bg_url + ")"
  };

  return (
    <div class="whole_page" style={background}>
      <LoginContext.Provider value={{ loggedIn, setLoggedIn }}>
        <div class="logo">
          <Logo />
          <div class="navBar">
            <NavBar content={page_content} />
          </div>
        </div>
        <div>
          <LoginBox content={page_content} />
        </div>
      </LoginContext.Provider>
    </div>
  );
}

export default Home;
