import React from 'react';
import Home from './components/Home/Home';
import { Portal } from './components/Portal/Portal';
import Register from './components/Register/Register';
import SensorPage from './components/SensorPage/SensorPage';
import NavBar from './components/NavBar/NavBar';
import { Routes, Route } from 'react-router-dom';
import './index.css';

export const PageRoutes = () => {

  console.log('IN ROUTES');
    
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/portal" element={<Portal />} />
      <Route path="/sensor/:serial_number" element={<SensorPage />} />
    </Routes>
  );
};
