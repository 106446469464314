import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Logo from "../Logo/Logo.js";

import PropTypes from 'prop-types';

import './NavBar.css';
import useClasses from '../../hooks/styles';
import { LoginContext } from "../../contexts/LoginContext";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Colca
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function NavBar(props) {
    const theme = useTheme();

    const styles = {
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        button: {
            color: props.content.font_color,
            border: '1px solid',
            borderColor: props.content.font_border_color,
            marginRight: theme.spacing(1),
        },
        align_right: {
            marginLeft: 'auto',
            marginRight: -12,
        }
    };
    const classes = useClasses(styles);

    const { loggedIn, setLoggedIn } = useContext(LoginContext);
    console.log(loggedIn);

    const history = useNavigate();
    const handle_logout = (e) => {
        e.preventDefault();
        localStorage.removeItem('access');
        setLoggedIn(false);
        history("/");
    }

    return (
        <div>
            <AppBar position="sticky" style={{ background: 'transparent', boxShadow: 'none'}}>
                <Toolbar>
                    <section className={classes.align_right}>
                        <Link to="/" style={{ textDecoration: 'none', marginRight: 8 }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                className={classes.button}
                            >
                                Home
                            </Button>
                        </Link>
                        <Link to="/portal" style={{ textDecoration: 'none', marginRight: 8 }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                className={classes.button}
                            >
                                Portal
                            </Button>
                        </Link>

                        { loggedIn && 
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                className={classes.button}
                                onClick={event => {handle_logout(event)}}
                                style={{ textDecoration: 'none', marginRight: 8 }}
                            >
                                Log Out
                            </Button>
                        }
                    </section>
                </Toolbar>
            </AppBar>
        </div>
    );
}
  
export default NavBar;

NavBar.propTypes = {
    content: PropTypes.object.isRequired,
};