import React, { useEffect, useState, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import EqualizerIcon from "@mui/icons-material/Equalizer";

import Box from '@mui/material/Box';

import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';

import "./Portal.css";
import NavBar from "../NavBar/NavBar";
import GraphBox from "../GraphBox/GraphBox";
import TableBox from "../TableBox/TableBox";
import useClasses from "../../hooks/styles";
import { SensorContext } from "../../contexts/SensorContext";
import { LoginContext } from "../../contexts/LoginContext";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Colca
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

function CustomListItem({ sensor }) {
  const [expanded, setExpanded] = useState(false);
  const history = useNavigate();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  function handleItemClick (index) {
    history(`/sensor/${ sensor.serial_number }`);
  };

  return (
    <div>
      <ListItem key={sensor.serial_number} disablePadding>
        <ListItemButton onClick={handleExpandClick}>
          <ListItemText primary={sensor.location} />
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </ListItemButton>
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ListItemButton onClick={handleItemClick} sx={{ pl: 4 }}>
          <ListItemText primary="Edit" slotProps={{ "primary": { align: 'right' } }} />
        </ListItemButton>
      </Collapse>
    </div>
  )
};

const drawerWidth = 240;
export const Portal = (props) => {
  // Set Context to have a global variable between components
  const [content, setContent] = useState({});
  const [data, setData] = useState([]);
  const [slist, setList] = useState([]);
  const [serial, setSerial] = useState("");
  const [sensor, setSensor] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [range, setRange] = useState(1);
  const [ready, setReady] = useState(false);
  const [value, setValue] = React.useState('1');
  
  // States used for tabs
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const theme = useTheme();

  const styles = theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    container: {
      margin: theme.spacing(3, 0, 3),
    },
    tab: {
      backgroundColor: theme.palette.secondary.main,
      display: "flex",
      flexDirection: "column",
      alignItems: "self-start",
      verticalAlign: "top",

    },
  });
  const classes = useClasses(styles);

  const history = useNavigate();
  useEffect(() => {
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
    fetch(`${process.env.REACT_APP_CMS_URL}/api/users/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access"),
      },
    }).then((response) => {
      if (!response.ok) {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        setLoggedIn(false);
        history("/");
      } else {
        console.log("no token");
        fetch(`${process.env.REACT_APP_CMS_URL}/api/pages?filters[type][$eq]=portal&populate=*`)
          .then((res) => res.json())
          .then((json) => {
            setContent(json.data[0]);
          })
          .then((after) => {
            fetch(`${process.env.REACT_APP_CMS_URL}/api/sensors`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access"),
              },
            })
              .then((r) => r.json())
              .then((j) => {
                console.log("setting data");
                console.log(j);
                setData(j.data);
                setLoggedIn(true);
              });
          });
      }
    });
  }, []);

  useEffect(() => {
    console.log("serial change");
    console.log(serial);
    if (serial != "") {
      setReady(true);
    }
  }, [serial]);

  useEffect(() => {
    console.log("data altered");
    if (data.length) {
      console.log("found data");
      console.log(data);
      setList([...new Set(data.map((item) => { return { serial_number: item.serial_number, location: item.location } }))]);
    }
  }, [data]);

  useEffect(() => {
    console.log(slist);
    console.log(slist.length);  
    if (slist.length) {
      setSerial(slist[0].serial_number);
      setSensor(slist[0]);
      console.log(slist[0]);
      console.log("serial set");
    }
  }, [slist]);

  useEffect(() => {
    console.log("Range change");
  }, [range]);

  function handleChange(event, newValue) {
    setValue(newValue);
  };

  function handleSerial(snum) {
    console.log("in function");
    setSerial(snum);
  }

  function handleMenuOpen(index, event) {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setOpen(true);
    setTabValue(index);
    console.log("VALUE:");
    console.log(index);
  };

  function handleMenuClose() {
    console.log("on handlemenuclose");
    setAnchorEl(null);
    setOpen(false);
  };

  function SensorLabel(sensor_object) {

    console.log("1111111111111111111111111111111111111");
    console.log(sensor_object);
    console.log(sensor_object.location);

    return (
      <Typography noWrap={true}>
        { sensor_object.sensor_object.location }
      </Typography>
    );
  }

  if (ready) {
    console.log('==================');
    console.log(content);
    console.log(sensor);
    //const bg_url =
    //  `${process.env.REACT_APP_CMS_URL}` + content.background_image.data.url;
    const background = {
      overflow: "auto",
      //backgroundImage: "url(" + bg_url + ")",
    };

    return (
      <SensorContext.Provider value={{ sensor, setSensor }}>
        <div class="background" style={background}>
          <AppBar position="sticky" sx={{ border: 1, boxShadow: 1, zIndex: (theme) => theme.zIndex.drawer + 1, background: "white" }}>
            <Toolbar>
              <div class="navWrapper">
                <LoginContext.Provider value={{ loggedIn, setLoggedIn }}>
                  <NavBar content={content} />
                </LoginContext.Provider>
              </div>
            </Toolbar>
          </AppBar>
          <Box>
            <Drawer
              variant="permanent"
              elevation={0}
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
              }}
            >
              <Toolbar />
              <Box sx={{ overflow: 'auto' }}>
                <List>
                  <ListItem key={"overview"} disablePadding>
                    <ListItemButton>
                      <ListItemText primary={"Overview"} />
                    </ListItemButton>
                  </ListItem>
                </List>
                <List>
                  {slist.map((sensor, index) => {
                    return (
                      <div>
                        <CustomListItem key={index} sensor={sensor} />
                      </div>
                    )
                  })}
                </List>
              </Box>
            </Drawer>

            
            <Box sx={{ pl: 30 }}>
              <Toolbar />
              <Container
                maxWidth="lg"
              >
                <TableBox serials={[data]}/>
              </Container>

              <Container
                maxWidth="lg"
              >
                <GraphBox key={[serial, range]} serial={serial} range={range} />
              </Container>
            </Box>
          </Box>
        </div>
      </SensorContext.Provider>
    );
  } else {
    return "LOADING...";
  }
}
